
/*Antom - Style started*/
/* .MuiChip-filled{color:#fff;background-color: hsl(212, 35%, 40%);} */
*{word-break: break-word;}
body,.MuiDialog-paper,.theme-bg{background-color: #ebeff2;}
.theme-border, .text-area{border:1px solid #4089da}
.sm-theme-box-shadow{box-shadow: 2px 2px 5px #bebebe, -2px -2px 5px #ffffff !important;}
.sm-theme-box-shadow-blk,.sm-theme-box-shadow-blk:hover{box-shadow: 0px 0px 5px rgba(0,0,0,0.5)}
.theme-box,.theme-box-xs-hide {box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;background-color: #ebeff2 !important;border-radius:10px !important;}
.theme-box-2{box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;border-radius:10px;}
.theme-box-w{box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;background-color: #fff !important;border-radius:10px !important;}
.theme-box-shadow{box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;}
.sm-theme-box {box-shadow: 2px 2px 5px #bebebe, -2px -2px 5px #ffffff !important;background-color: #ebeff2;border-radius:10px;}
.xs-icon-btn,.sm-icon-btn{box-shadow: 2px 2px 5px #bebebe, -2px -2px 5px #ffffff !important;background-color: #ebeff2;   border: 1px solid #4089da;}
.sm-icon-btn{border-radius: 10px;}
.xs-icon-btn{border-radius: 5px;}
.md-icon-btn, .lg-icon-btn{background-color: #ebeff2;border-radius:10px;box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;box-sizing:border-box;border: 1px solid #4089da;}
.md-icon-btn svg{width:20px;height: 20px;}
.lg-icon-btn svg{width:22px;height: 22px;}
.MuiCheckbox-root, .MuiCheckbox-root.Mui-checked{color:#4089da;}
.main-input-box .inner-input-box > div,.text-area, .inner-input-bluebox .inner-input-box > div {box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;background-color: #ebeff2;border-radius:10px;}
.sm-main-input-box .sm-inner-input-box > div{box-shadow: 2px 2px 5px #bebebe, -2px -2px 5px #ffffff !important;background-color: #ebeff2;border-radius:10px;}
.of-hidden{overflow: hidden;}
.main-input-box fieldset.MuiOutlinedInput-notchedOutline,.text-area .DraftEditor-root{border-radius:10px;}
.main-input-box .MuiInputBase-colorPrimary fieldset,.main-input-box .MuiInputBase-colorPrimary:hover .MuiOutlinedInput-notchedOutline, .main-input-box .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline,.inner-input-bluebox .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline{border-color:#4089da;border-radius:10px;}
.main-input-box .MuiInputBase-colorPrimary.Mui-disabled:hover .MuiOutlinedInput-notchedOutline{border-color: rgba(0, 0, 0, 0.26);}
.media-type{margin:0;}
.DraftEditor-root{border:0;padding: 10px;}
.MuiToggleButton-root{box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;border-radius: 10px;}
.MuiToggleButton-root, .MuiToggleButton-root:hover, .MuiToggleButton-root.Mui-selected,.MuiToggleButton-root.Mui-selected:hover{background: #43658b;color:#fff;text-transform: none;font-size: 1rem;}
.theme-blue-icon-btn,.theme-blue-icon-btn:hover{background-color: #4089da;color: #fff;}
.staff_day_in .MuiDataGrid-cell{font-size:1rem;}
.mark_scholar_attendance .MuiButton-outlined.MuiButton-outlinedSuccess{border-color: #3bb6d0;color:#3bb6d0}
.mark_scholar_attendance .MuiButton-contained.MuiButton-containedSuccess{background-color:#3bb6d0;color:#fff ;}
.mark_scholar_attendance .MuiButton-outlined.MuiButton-outlinedError{border-color: #dc4a65;color:#dc4a65}
.mark_scholar_attendance .MuiButton-contained.MuiButton-containedError{background-color:#dc4a65;color:#fff ;}
.day_report_table .MuiDataGrid-columnHeaders, .fee_class_wise_table .MuiDataGrid-columnHeaders, .fee_item_wise_table .MuiDataGrid-columnHeaders, .fee_term_wise_table .MuiDataGrid-columnHeaders{background-color: #486D95;}
.day_report_table .MuiDataGrid-columnHeaderTitle, .fee_class_wise_table .MuiDataGrid-columnHeaderTitle, .fee_item_wise_table .MuiDataGrid-columnHeaderTitle, .fee_term_wise_table .MuiDataGrid-columnHeaderTitle{color:#fff;}
.day_report_table .MuiDataGrid-virtualScrollerRenderZone, .fee_class_wise_table .MuiDataGrid-virtualScrollerRenderZone, .fee_item_wise_table .MuiDataGrid-virtualScrollerRenderZone, .fee_term_wise_table .MuiDataGrid-virtualScrollerRenderZone{background-color: #fff;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;}
.MuiDataGrid-cell:focus{outline: none !important;}
.day_report_table .fee_cancel_receipts {justify-content: center;}
.day_report_table .MuiDataGrid-iconButtonContainer svg, .fee_class_wise_table .MuiDataGrid-iconButtonContainer svg, .fee_item_wise_table .MuiDataGrid-iconButtonContainer svg, .fee_term_wise_table .MuiDataGrid-iconButtonContainer svg{color:#fff}
.MuiDialogContent-root{padding:20px 16px 0 16px;}

/*Post styles*/
.player_link{max-width:100%;}
.main_post_title{font-size: 1.1rem;font-weight: 600;color: #232323;padding-top:6px;padding-bottom: 6px;}
.main_post_content{max-width: '100%';  overflow-y: 'auto';  scrollbar-width: 'none';  position: relative;     color: #424242}
.main_post_content p span,.main_post_content p{font-size: 0.9rem !important;  font-family: "Nunito", sans-serif !important;}
.post_content_box {max-width: 100%; padding:20px; scrollbar-width: none;float: left;min-height: 200px;height: auto;    position: relative}
.post_content_box p, .post_content_box p > span{font-size: 1rem !important;  line-height: 1.6;  font-weight: 500;font-family: "Nunito", sans-serif !important;}

/*Media queries started*/

@media screen and (min-width:0) {
    h3{font-size: 1rem;}
    .theme-box-xs-hide{box-shadow: none !important;}
    .media-type button svg{font-size: 1.3rem;}
    .media-type p{position: absolute;top:-12px;padding:0 6px;background-color: #ebeff2;font-size:0.8rem;color: #43658b;}
    .post_dialog .MuiDialog-paper{max-width: 100%;width:100%;} 
    .sm-icon-btn{width: 26px;height: 26px;border-radius: 5px;}
    .xs-icon-btn{width:24px;height:24px;}
    .xs-icon-btn svg{font-size: 1rem;} 
    .post-input-box .MuiInputBase-root{width: 100%;}
    .sm-icon-btn svg{font-size: 1.3rem;}
    .inner-post-main .MuiDivider-root + div {padding:0 !important;margin: 0 !important;}
    .inner-post-main .MuiDivider-root + div .MuiStack-root > .MuiStack-root {padding: 0 !important;}
    .attendance-list h3{margin-left: 0 ;}
    .att_stud_report > div{margin-bottom: 0;}
    .md-icon-btn{padding:4px;border-radius: 6px;box-shadow: 2px 2px 5px #bebebe, -2px -2px 5px #ffffff !important;}
    .md-icon-btn svg{width:20px;height: 20px;}

  }
  @media screen and (min-width:600px) {
    
    h3{font-size: 1.17rem;}
    .theme-box-xs-hide{box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff !important;}
    .media-type button svg{font-size: 1.5rem;}
    .media-type p{position: initial;background-color:transparent;font-size:initial;color:#000000f2;}
    .post_dialog .MuiDialog-paper{max-width: 100%;width:100%;}
    .sm-icon-btn{width:32px;height:32px;}
    .sm-icon-btn svg{font-size: 1.5rem;}
    .xs-icon-btn{width:24px;height:24px;}
    .xs-icon-btn svg{font-size: 1rem;}   
    .post-input-box .MuiInputBase-root{width: 100%;}    
    .inner-post-main .MuiDivider-root + div {padding:10px !important;margin: 10px !important;}
    .attendance-list h3{margin-left: 5px !important;}
    
    
  }
  @media screen and (min-width:900px) {
    .post_dialog .MuiDialog-paper{max-width: 700px;width:700px;}   
    
  }
  @media screen and (min-width:1200px) {
    .post_dialog .MuiDialog-paper{max-width: 900px;width:900px;}
  }

  @media screen and (min-width:1536px) {
    .post_dialog .MuiDialog-paper{max-width: 1200px;width:1200px;}
  }
  

/*Antom - Style ended*/